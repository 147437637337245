// import React, { useEffect, useState } from 'react';
// import { string, bool } from 'prop-types';
// import { compose } from 'redux';
// import { Form as FinalForm } from 'react-final-form';
// import classNames from 'classnames';

// import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
// import * as validators from '../../../util/validators';
// import { ATHLETE, BRAND, propTypes, SPORTTEAM } from '../../../util/types';
// import {
//   isErrorNoPermissionForInitiateTransactions,
//   isErrorNoPermissionForUserPendingApproval,
//   isTooManyRequestsError,
// } from '../../../util/errors';
// import { types as sdkTypes } from '../../../util/sdkLoader';
// import {
//   partnershipLength,
//   partnershipLengthFi,
//   partnershipStartLength,
//   partnershipStartLengthFi,
//   sponsorshipTypeData,
//   sponsorshipTypeDataFi,
// } from '../../../config/configListing';

// import {
//   Form,
//   PrimaryButton,
//   FieldTextInput,
//   IconInquiry,
//   Heading,
//   NamedLink,
//   FieldCheckboxGroup,
//   FieldCurrencyInput,
//   FieldRadioButton,
//   FieldCheckbox,
//   FieldSelect,
// } from '../../../components';

// import css from './InquiryForm.module.css';
// import { NO_ACCESS_PAGE_INITIATE_TRANSACTIONS } from '../../../util/urlHelpers';
// import { useSelector } from 'react-redux';
// import { formatMoney } from '../../../util/currency';
// import appSettings from '../../../config/settings';
// import arrayMutators from 'final-form-arrays';
// const ErrorMessage = props => {
//   const { error } = props;
//   const userPendingApproval = isErrorNoPermissionForUserPendingApproval(error);
//   const userHasNoTransactionRights = isErrorNoPermissionForInitiateTransactions(error);

//   // No transaction process attached to listing
//   return error ? (
//     <p className={css.error}>
//       {error.message === 'No transaction process attached to listing' ? (
//         <FormattedMessage id="InquiryForm.sendInquiryErrorNoProcess" />
//       ) : isTooManyRequestsError(error) ? (
//         <FormattedMessage id="InquiryForm.tooManyRequestsError" />
//       ) : userPendingApproval ? (
//         <FormattedMessage id="InquiryForm.userPendingApprovalError" />
//       ) : userHasNoTransactionRights ? (
//         <FormattedMessage
//           id="InquiryForm.noTransactionRightsError"
//           values={{
//             NoAccessLink: msg => (
//               <NamedLink
//                 name="NoAccessPage"
//                 params={{ missingAccessRight: NO_ACCESS_PAGE_INITIATE_TRANSACTIONS }}
//               >
//                 {msg}
//               </NamedLink>
//             ),
//           }}
//         />
//       ) : (
//         <FormattedMessage id="InquiryForm.sendInquiryError" />
//       )}
//     </p>
//   ) : null;
// };
// const { Money } = sdkTypes;
// // NOTE: this InquiryForm is only for booking & purchase processes
// // The default-inquiry process is handled differently
// const InquiryFormComponent = props => (
//   <FinalForm
//     mutators={{ ...arrayMutators }}
//     keepDirtyOnReinitialize={true}
//     {...props}
//     render={fieldRenderProps => {
//       const {
//         rootClassName,
//         className,
//         submitButtonWrapperClassName,
//         formId,
//         handleSubmit,
//         inProgress,
//         intl,
//         listingTitle,
//         authorDisplayName,
//         sendInquiryError,
//         currentUser,
//         form,
//         config,
//         values,
//         invalid,
//         priceArray,
//         listing
//       } = fieldRenderProps;

//       const { listingType } = listing?.attributes?.publicData || {};
//       const { athleteNumber, clubBankAccount } = currentUser?.attributes?.profile?.protectedData || {};
//       const { clubEmailAddress, userType } = currentUser?.attributes?.profile?.publicData || {};
//       const { stripeConnected } = currentUser?.attributes || {};


//       const showPaymentoptionMaybe = [ATHLETE, SPORTTEAM].includes(userType) && athleteNumber && clubBankAccount && clubEmailAddress && stripeConnected;
//       const [paymentPreference, setPaymentPreference] = useState(() =>
//         stripeConnected && clubBankAccount ? '' : stripeConnected ? 'stripe' : 'club'
//       );
//       const isShowPrice = listing?.attributes?.publicData?.priceShow === "yes";
//       const language = typeof window != 'undefined' && localStorage.getItem('language');
//       useEffect(() => {
//         form.change('paymentPreference', paymentPreference)
//       })
//       const currentUserListing = useSelector(state => state?.user?.currentUserListing);
//       const teamName = userType === ATHLETE ? currentUserListing?.attributes?.publicData?.teamName : null;
//       const unitType = listing?.attributes?.publicData?.unitType;
//       const marketplaceCurrency = config?.currency;
//       const messageLabel = intl.formatMessage(
//         {
//           id: 'InquiryForm.messageLabel',
//         },
//         { authorDisplayName }
//       );
//       const messagePlaceholder = intl.formatMessage(
//         {
//           id: 'InquiryForm.messagePlaceholder',
//         },
//         { authorDisplayName }
//       );
//       const messageRequiredMessage = intl.formatMessage({
//         id: 'InquiryForm.messageRequired',
//       });
//       const paymentPerformanceLabel = intl.formatMessage({ id: 'PaymentPageForm.PaymentMethod' }); 
//       const fromdata = intl.formatMessage({
//         id: 'ListingPage.from',
//       });

//       const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

//       const classes = classNames(rootClassName || css.root, className);
//       const submitInProgress = inProgress;
//       // const submitDisabled = submitInProgress;
//       const brandDisableFields = userType === BRAND &&
//         (!values?.deliverableItems?.length ||
//           !values?.partnershipStartLength?.length ||
//           !values?.partnershipLength);

//       const submitDisabled =
//         invalid ||
//         submitInProgress ||
//         brandDisableFields ||
//         !values?.inquiryPrice?.amount > 25000 ||
//         !values.message;

//       const partnershipStartLengthdynamic =
//         language === 'Finnish' ? partnershipStartLengthFi : partnershipStartLength;
//       const dynamicsponsorshipTypeData =
//         language === 'Finnish' ? sponsorshipTypeDataFi : sponsorshipTypeData;
//       const dynamicPartnershipData =
//         language === 'Finnish' ? partnershipLengthFi : partnershipLength;

//       // Check Box  for Start Partnership
//       const partnershipStartLengthProps = {
//         id: 'partnershipStartLength',
//         name: 'partnershipStartLength',
//         label: <FormattedMessage id="NegotiationInquiryForm.partnershipStartLength" />,
//         // label: "When would you like partnership to start ?*",
//         options: [...partnershipStartLengthdynamic],
//         twoColumns: true,
//         showWith: true
//       };

//       const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
//         const priceRequiredMsgId = { id: 'EditListingPricingAndStockForm.priceRequired' };
//         const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
//         const priceRequired = validators.required(priceRequiredMsg);

//         const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
//         const minPrice = formatMoney(intl, minPriceRaw);
//         const priceTooLowMsgId = { id: 'EditListingPricingAndStockForm.priceTooLow' };
//         const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
//         const minPriceRequired = validators.moneySubUnitAmountAtLeast(
//           priceTooLowMsg,
//           listingMinimumPriceSubUnits
//         );
//         return listingMinimumPriceSubUnits
//           ? validators.composeValidators(priceRequired, minPriceRequired)
//           : priceRequired;
//       };

//       const listingMinimumPriceSubUnits = 25000;
//       const priceValidators = getPriceValidators(
//         listingMinimumPriceSubUnits,
//         marketplaceCurrency,
//         intl
//       );

//       const SelectPaymentMethodMessage = intl.formatMessage({
//         id: 'accountNumberClubPaymentForm.emailRequired',
//       });
//       const SelectPaymentMethodRequired = validators.required(SelectPaymentMethodMessage);

//       return (
//         <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
//           <IconInquiry className={css.icon} />
//           {userType === BRAND ? (
//             <Heading as="h2" rootClassName={css.heading}>
//               <FormattedMessage id="InquiryForm.heading" values={{ listingTitle }} />
//             </Heading>
//           ) : (
//             <Heading as="h2" rootClassName={css.heading}>
//               <FormattedMessage id="InquiryForm.headingAthlete" values={{ teamName }} />
//             </Heading>
//           )}
//           <FieldTextInput
//             className={css.field}
//             type="textarea"
//             name="message"
//             id={formId ? `${formId}.message` : 'message'}
//             label={messageLabel}
//             placeholder={messagePlaceholder}
//             validate={messageRequired}
//           />
//           <div>
//             {listingType === ATHLETE ? (
//               <div>
//                 {isShowPrice ? (
//                   <div>
//                     <label>
//                       <FormattedMessage id="NegotiationInquiryForm.deliverableItems" />
//                     </label>
//                     <div className={css.patnershipHeading}>
//                       {priceArray.map((item, index) => {
//                         const { price } = item;
//                         const priceIs = price ? formatMoney(intl, price) : null;
//                         return (
//                           <FieldCheckbox
//                             id={`${index}.deliverableItems`}
//                             name="deliverableItems"
//                             label={priceIs ? item?.label + ` ${fromdata} ` + priceIs : item.label}
//                             value={item.key}
//                             required={true}
//                           />
//                         );
//                       })}
//                     </div>
//                   </div>
//                 ) : null}
//                 <FieldSelect
//                   id={'partnershipLength'}
//                   className={css.quantityField}
//                   name="partnershipLength"
//                   label={intl.formatMessage({ id: 'NegotiationInquiryForm.partnershipLength' })}
//                 >
//                   <option disabled value="">
//                     {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
//                   </option>
//                   {dynamicPartnershipData.map(pt => (
//                     <option key={pt.key} value={pt.key}>
//                       {pt.label}
//                     </option>
//                   ))}
//                 </FieldSelect>

//                 <div className={css.patnershipStartBox}>
//                   <FieldCheckboxGroup {...partnershipStartLengthProps} />
//                 </div>
//               </div>
//             ) : null}
//           </div>

//           <div>
//             <FieldCurrencyInput
//               id={`${formId}inquiryPrice`}
//               name="inquiryPrice"
//               className={css.priceInput}
//               label={intl.formatMessage({ id: 'NegotiationForm.Price' }, { unitType })}
//               placeholder={intl.formatMessage({
//                 id: 'EditListingPricingAndStockForm.priceInputPlaceholder',
//               })}
//               currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
//               validate={priceValidators}
//             />
//             <input name="paymentPreference" type="hidden" value={paymentPreference} />
//             {/* Payment Options */}
//             {showPaymentoptionMaybe ?
//               <div className={css.paymentRadioBox}>
//                 <label className={css.paymentPerformanceLabel}>{paymentPerformanceLabel}</label>
//                 {/* Radio Button for payment optinos  */}
//                 <div className={css.radioWrapper}>
//                   <FieldRadioButton
//                     id="paymentPerformanceStripe"
//                     name="paymentPerformance"
//                     value="stripe"
//                     label={intl.formatMessage({
//                       id: 'PaymentPageForm.PaymentMethodPreferenceStripe',
//                     })}
//                     onClick={e => {
//                       setPaymentPreference(e.target.value);
//                     }}
//                     validate={validators.composeValidators(SelectPaymentMethodRequired)}
//                   />
//                 </div>
//                 <div className={css.radioWrapper}>
//                   <FieldRadioButton
//                     id="paymentPerformanceClub"
//                     name="paymentPerformance"
//                     value="club"
//                     label={intl.formatMessage({
//                       id: 'PaymentPageForm.PaymentMethodPreferenceClub',
//                     })}
//                     onClick={e => {
//                       setPaymentPreference(e.target.value);
//                     }}
//                     validate={validators.composeValidators(SelectPaymentMethodRequired)}
//                   />
//                 </div>
//               </div> : null}
//           </div>
//           <div className={submitButtonWrapperClassName}>
//             <ErrorMessage error={sendInquiryError} />
//             <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
//               <FormattedMessage id="InquiryForm.submitButtonText" />
//             </PrimaryButton>
//           </div>
//         </Form>
//       );
//     }}
//   />
// );

// InquiryFormComponent.defaultProps = {
//   rootClassName: null,
//   className: null,
//   submitButtonWrapperClassName: null,
//   inProgress: false,
//   sendInquiryError: null,
// };

// InquiryFormComponent.propTypes = {
//   rootClassName: string,
//   className: string,
//   submitButtonWrapperClassName: string,

//   inProgress: bool,

//   listingTitle: string.isRequired,
//   authorDisplayName: string.isRequired,
//   sendInquiryError: propTypes.error,

//   // from injectIntl
//   intl: intlShape.isRequired,
// };

// const InquiryForm = compose(injectIntl)(InquiryFormComponent);

// InquiryForm.displayName = 'InquiryForm';

// export default InquiryForm;


import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import {
  isErrorNoPermissionForInitiateTransactions,
  isErrorNoPermissionForUserPendingApproval,
  isTooManyRequestsError,
} from '../../../util/errors';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconInquiry,
  Heading,
  NamedLink,
} from '../../../components';

import css from './InquiryForm.module.css';
import { NO_ACCESS_PAGE_INITIATE_TRANSACTIONS } from '../../../util/urlHelpers';

const ErrorMessage = props => {
  const { error } = props;
  const userPendingApproval = isErrorNoPermissionForUserPendingApproval(error);
  const userHasNoTransactionRights = isErrorNoPermissionForInitiateTransactions(error);

  // No transaction process attached to listing
  return error ? (
    <p className={css.error}>
      {error.message === 'No transaction process attached to listing' ? (
        <FormattedMessage id="InquiryForm.sendInquiryErrorNoProcess" />
      ) : isTooManyRequestsError(error) ? (
        <FormattedMessage id="InquiryForm.tooManyRequestsError" />
      ) : userPendingApproval ? (
        <FormattedMessage id="InquiryForm.userPendingApprovalError" />
      ) : userHasNoTransactionRights ? (
        <FormattedMessage
          id="InquiryForm.noTransactionRightsError"
          values={{
            NoAccessLink: msg => (
              <NamedLink
                name="NoAccessPage"
                params={{ missingAccessRight: NO_ACCESS_PAGE_INITIATE_TRANSACTIONS }}
              >
                {msg}
              </NamedLink>
            ),
          }}
        />
      ) : (
        <FormattedMessage id="InquiryForm.sendInquiryError" />
      )}
    </p>
  ) : null;
};

// NOTE: this InquiryForm is only for booking & purchase processes
// The default-inquiry process is handled differently
const InquiryFormComponent = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize={true}
    render={fieldRenderProps => {
      const {
        listing,
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendInquiryError,
      } = fieldRenderProps;
      const { teamName } = listing?.attributes.publicData || {} ;
   
// listingTitle && listingTitle != 'undefined undefined';
      const messageLabel = intl.formatMessage(
        {
          id: 'InquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'InquiryForm.messagePlaceholder',
        }
        // ,{ authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'InquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;
      // const { listingType,  } = listing?.attributes?.publicData || {};  
      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconInquiry className={css.icon} />
          {(!listingTitle || listingTitle === 'undefined undefined') ? (
            <Heading as="h2" rootClassName={css.heading}>
              <FormattedMessage id="InquiryForm.heading2" values={{teamName}} />
            </Heading>
          ) : (
            <Heading as="h2" rootClassName={css.heading}>
              <FormattedMessage id="InquiryForm.heading" values={{listingTitle}} />
            </Heading>
          )}

          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />
          <div className={submitButtonWrapperClassName}>
            <ErrorMessage error={sendInquiryError} />
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="InquiryForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

InquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendInquiryError: null,
};

InquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendInquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const InquiryForm = compose(injectIntl)(InquiryFormComponent);

InquiryForm.displayName = 'InquiryForm';

export default InquiryForm;